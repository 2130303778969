import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

var routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/index.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../pages/case.vue')
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('../pages/form.vue')
  },
  {
    path: '/company1',
    name: 'company1',
    component: () => import('../pages/company.vue')
  },
  {
    path: '/company2',
    name: 'company2',
    component: () => import('../pages/company.vue')
  },
  {
    path: '/company3',
    name: 'company3',
    component: () => import('../pages/company.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../pages/news.vue')
  },
  {
    path: '/news2',
    name: 'news2',
    component: () => import('../pages/news2.vue')
  },
  {
    path: '/news3',
    name: 'news3',
    component: () => import('../pages/news3.vue')
  },
  {
    path: '/news4',
    name: 'news4',
    component: () => import('../pages/news4.vue')
  },
  {
    path: '/news5',
    name: 'news5',
    component: () => import('../pages/news5.vue')
  },
  {
    path: '/news6',
    name: 'news6',
    component: () => import('../pages/news6.vue')
  },
  {
    path: '/news7',
    name: 'news7',
    component: () => import('../pages/news7.vue')
  },
  {
    path: '/news8',
    name: 'news8',
    component: () => import('../pages/news8.vue')
  }
]

const router = new VueRouter({
  routes,
  mode:"history",
  scrollBehavior({query:{scrollInto = 0}}) {
    return {x:0,y:Number(scrollInto)};
  }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
